.policyPage {
  max-width: 1400px;
  margin: 0 auto;
  font-size: 2rem;

  h2 {
    margin: 1rem 0;
  }

  li {
    list-style: none;
  }
}
