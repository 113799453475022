.slider-container {
  position: relative;

  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 50%;
    margin: -22px 16px 0 16px;

    img {
      width: 44px;
      height: 44px;
      box-shadow: 4px 4px 12px 0px rgba(66, 68, 90, 0.4);
      border-radius: 50%;
    }
  }
}
