.map-page {
  padding: 2em 0;

  .columns {
    @include XGA {
      display: flex;
    }
  
    .left-column {
      @include XGA {
        width: 32%;
      }
    }
  
    .right-column {
      @include XGA {
        width: 32%;

        max-width: 936px;
        max-height: 500px; 
      }
    }
  }

  .searchbar {
    margin: 1.5em 0;

    .search {
      width: 100%;
      max-width: 430px;
      padding: 10px;
      background-color: #EFEFEF;
      font-size: 15px;
      background-image: url(../../assets/img/search.svg);
      background-position: 98% center;
      background-repeat: no-repeat;
      border: 0;
    }
  }
}