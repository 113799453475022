* {
  box-sizing: border-box;
}

.sidebar {
  position: absolute;
  width: 33.3333%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.map {
  position: absolute;
  left: 33.3333%;
  width: 66.6666%;
  top: 0;
  bottom: 0;
}

h1 {
  font-size: 22px;
  margin: 0;
  line-height: 30px;
  padding: 20px 2px;
}

a {
  color: #404040;
  text-decoration: none;
}

a:hover {
  color: #101010;
}

.heading {
  background: #fff;
  border-bottom: 1px solid #eee;
  min-height: 60px;
  line-height: 60px;
  padding: 0 10px;
  color: #fff;
}

.listings {
  height: 100%;
  overflow: auto;
  padding-bottom: 60px;
}

.listings .item {
  display: block;
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-decoration: none;
}

.listings .item:last-child {
  border-bottom: none;
}

.listings .item .title {
  display: block;
  color: #041e42;
  font-weight: 700;
  font-size: 20px;
}

.listings .item .address-detail {
  font-size: 14px;
}

.listings .item .title small {
  font-weight: 400;
}

.listings .item.active .title,
.listings .item .title:hover {
  color: #00add5;
}

.listings .item.active {
  background-color: #f8f8f8;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #041e42;
  border-radius: 0;
}

.marker {
  border: none;
  cursor: pointer;
  height: 15px;
  width: 15px;
  background-image: red;
}

/* Marker tweaks */
.mapboxgl-popup {
  padding-bottom: 50px;
}

.mapboxgl-popup-close-button {
  display: block;
  width: 25px;
  height: 25px;
  font-size: 1rem;
  color: white;
  background-color: red;
  border-radius: 50px;
  top: -25px;
  right: -10px;

  transition: all 0.2s;
}

.mapboxgl-popup-close-button:hover {
  background-color: rgb(218, 21, 21);
}

.mapboxgl-popup-content {
  font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", sans-serif;
  padding: 0;
  width: 180px;
}

.mapboxgl-popup-content h3 {
  background: #103465;
  color: #fff;
  margin: 0;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
  margin-top: -15px;
}

.mapboxgl-popup-content h4 {
  margin: 0;
  padding: 10px;
  font-weight: 400;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #103465;
}

body {
  margin: 0;
  padding: 0;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80%;
  min-height: 500px;
  margin-left: 20px;
}

.map-page {
  height: 600px;
}

.map-page .left-column {
  height: 500px;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right {
  z-index: 0 !important;
}

.mapbox-directions-route-summary > h1 {
  color: #fff;
}

.mapbox-directions-profile input[type="radio"]:checked + label:hover,
.mapbox-directions-profile input[type="radio"]:checked + label {
  background: #eee;
  color: rgba(0, 0, 0, 0.75);
}

.currentLocation {
  display: flex;
  align-items: center;

  font-size: 16px;
  margin-top: 0.5em;
  margin-bottom: 1em;
  padding-left: 10px;
}

.currentLocation span {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.currentLocation img {
  filter: invert(100%);
  transition: all 0.3s;
}

.currentLocation span:hover > img {
  transform: scale(1.1) rotate(-90deg);
}

.mapbox-directions-profile {
  width: 350px;
}

@media screen and (max-width: 768px) {
  #map {
    top: 600px;
  }

  .sidebar {
    width: 100%;
  }

  .map-page {
    min-height: 1200px;
  }

  .mapboxgl-ctrl-top-right {
    z-index: 0;
  }
}
