@import '../../styles/breakpoints';

.header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #041e42;
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #041E42;


  .logo {
    width: 84px;
    height: 24px;
  }

  .logo img {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1200px) {
    padding: 2rem 0;
  }

  .map-link {
    position: absolute;
    top: 8px;
    right: calc(5% + 65px);
    text-align: center;

    @include HD {
      top: 22px;
    }

    a {
      display: block;
      text-decoration: none;
    }

    img {
      display: block;
      border: 0;
      margin: 0 auto;
    }

    span {
      display: block;
      color: #fff;
      font-size: 12px;
    }
  }

  .dropdown {
    position: absolute;
    top: 13px;
    right: 5%;
    font-size: 16px;
    color: #fff;
    border: 1px solid #041E42;
    background-color: #041E42;
    overflow: hidden;

    @include HD {
      top: 28px;
    }

    &.lang-dropdown {
      height: 30px;
      width: 52px;
    }

    &.active {
      height: auto;
      border: 1px solid #122f5a;
    }

    .selected {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
    }

    .dropdown-items {
      a {
        display: block;
        padding: 5px;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;

        &:hover {
          background-color: #122f5a;
        }
      }
    }
  }

  .nav-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 10px;
    left: calc(5% + 98px);
    cursor: pointer;
    width: 38px;

    @include HD {
      top: 24px;
    }

    img {
      display: block;
      width: 24px;
      height: auto;
    }

    span {
      display: block;
      color: #fff;
      font-size: 12px;
    }
  }

  .nav {
    display: none;
    z-index: 10;
    position: absolute;
    width: 100%;
    top: 56px;
    background-color: #041e42;
    padding: 10px 5%;

    &.active {
      display: block;
    }

    @include HD {
      left: calc(5% + 117px);
      top: 70px;
      width: auto;
      padding: 10px 15px;
      transform: translate(-50%, 0);
    }

    a {
      display: block;
      padding: 15px 10px;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      color: #fff;
      text-decoration: none;
      border-bottom: 2px solid rgba(255, 255, 255, 0.05);

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
}