@import '../../styles/breakpoints';

.faq-question {

  .question {
    position: relative;
    padding: 15px 35px 15px 0;
    font-size: 16px;
    font-weight: 700;
    color: #041E42;
    border-top: 1px solid #686868;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 12px;
      right: 0;
      width: 25px;
      height: 25px;
      background-image: url('./img/plus.svg');
    }

    @include HD {
      padding: 20px 35px 20px 0;
      font-size: 21px;
    }
  }

  .answer {
    display: none;
    color: #686868;
    font-size: 16px;

    p {
      margin: 1em 0;
    }
  }

  &.active {
    .answer {
      display: block;
    }

    .question {
      &:after {
        top: 22px;
        background-image: none;
        width: 20px;
        height: 2px;
        background-color: #041E42;
      }
    }
  }
}