@import "../../styles/_breakpoints.scss";

.footer {
  width: 100%;
  color: #fff;
  background-color: #041e42;
  padding-bottom: 80px;
}

.mobile-footer-year {
  margin-left: -16px;
  margin-top: 24px;
  @include XGA {
    display: none;
  }
}

.desktop-footer-year {
  display: none;
  margin-top: 40px;
  margin-bottom: -40px;
  font-size: 15px;
  @include XGA {
    display: block;
  }
}

.footer-inner {
  padding: 20px 0 80px;
  font-size: 15px;
  color: #fff;
  display: flex;
  flex-direction: column;

  @include XGA {
    flex-direction: row;
    padding: 40px 0 80px;
  }

  .logo {
    margin: 35px auto 0;
    height: 78px;
    order: 4;

    @include XGA {
      order: 0;
      margin: 20px 20px 0 0;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .contact-info {
    line-height: 2;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.footer-nav-block {

  @include XGA {
    margin: 0 20px;
  }

  h4 {
    font-size: 15px;
    font-weight: 700;
    border-bottom: 2px solid rgba(255,255,255,0.2);
    padding: 10px 0;
    margin: 10px 0 15px;
    text-transform: uppercase;
  }

  .footer-link {
    font-size: 15px;
    color: #fff;
    text-decoration: none;
    display: block;
    border: 0;
    text-transform: uppercase;
    padding: 5px 0;
    background: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.disclaimer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  font-size: 16px;
  background: #fff;
  color: #000;
  font-weight: bold;
  padding: 15px 20px;
  //text-transform: uppercase;
  border: 3px solid #000;
  z-index: 99;

  @include vHD {
    font-size: 24px;
    padding: 30px 30px;
  }

  .cookie-button {
    align-items: center;
    border: none;
    border-radius: 50%;
    bottom: 90px;
    box-shadow: -3px 3px 25px -3px rgba(0,0,0,.2);
    display: flex;
    height: 47px;
    justify-content: center;
    left: 20px;
    position: fixed;
    width: 47px;
  }
}

@media screen and (min-width: 720px) {
  .disclaimer .cookie-button {
      bottom: 125px;
      left: 40px;
  }
}